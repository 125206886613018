import React from 'react';

import CommonHeader from '../../../components/CommonHeader';
import OrganizationFieldConfigForm from '../components/OrganizationFieldConfigForm';
import OrganizationTab from '../components/OrganizationTab';

const OrganizationFieldConfig = () => (
  <OrganizationTab>
    <CommonHeader heading="Field Config" />
    <OrganizationFieldConfigForm />
  </OrganizationTab>
);

export default OrganizationFieldConfig;
