import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Card, Form } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { MODULES, ROUTES } from '@common/constants';
import CommonHeader from '@components/CommonHeader';
import { AppContext } from '@context';

import CommonTabHeader from '../../../components/CommonTabHeader';
import history from '../../../historyData';
import OrganizationMastersTab from '../../organizations/components/OrganizationMastersTab';
import HouseShapesForm from '../components/HouseShapesForm';
import { CREATE_HOUSE_SHAPE, UPDATE_HOUSE_SHAPE } from '../graphql/Mutations';
import { GET_HOUSE_SHAPE } from '../graphql/Queries';

const CreateHouseShapes = ({ isOrganization = false }) => {
  const { mode, id, orgId, orgMode } = useParams();
  const [form] = Form?.useForm();
  const { dispatch } = useContext(AppContext);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isView, setIsView] = useState(false);

  const [createHouseShape, { loading: createLoading }] = useMutation(
    CREATE_HOUSE_SHAPE,
  );

  const [updateHouseShape, { loading: updateLoading }] = useMutation(
    UPDATE_HOUSE_SHAPE,
  );

  const [getHouseShape, { data: houseShapeData }] = useLazyQuery(
    GET_HOUSE_SHAPE,
  );

  const fetchHouseShape = async () => {
    try {
      await getHouseShape({
        variables: { where: { id } },
        fetchPolicy: 'network-only',
      });
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    if (mode === 'view') {
      setIsView(true);
    }
    if (id) {
      fetchHouseShape();
    }
  }, []);

  const baseRoute = `${
    isOrganization ? `${ROUTES?.ORGANIZATIONS}/${orgMode}/${orgId}` : ''
  }${ROUTES?.MASTER}${ROUTES?.HOUSE_SHAPES}`;

  const onEdit = () => {
    setIsView(false);
    history?.push(`${baseRoute}/edit/${id}`);
  };

  const onCancelEdit = () => {
    setIsView(true);
    history?.push(baseRoute);
  };

  const onFinish = async (values) => {
    dispatch({
      type: 'SET_SHOW_PROMPT',
      data: false,
    });

    const {
      key,
      label,
      description,
      isActive,
      tooltipTitle,
      tooltipBody,
    } = values;

    try {
      if (id) {
        try {
          await updateHouseShape({
            variables: {
              where: { id },
              data: {
                key,
                label,
                description,
                isActive: JSON.parse(isActive),
                tooltip: { title: tooltipTitle, body: tooltipBody },
              },
            },
            onCompleted: () => onCancelEdit(),
          });
        } catch (error) {
          return error;
        }
      } else {
        try {
          await createHouseShape({
            variables: {
              data: {
                key,
                label,
                description,
                isActive: JSON.parse(isActive),
                tooltip: { title: tooltipTitle, body: tooltipBody },
              },
            },
            onCompleted: () => onCancelEdit(),
          });
        } catch (error) {
          return error;
        }
      }
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    if (houseShapeData?.houseShape?.data) {
      const dataToPass = {
        key: houseShapeData?.houseShape?.data?.key,
        label: houseShapeData?.houseShape?.data?.label,
        description: houseShapeData?.houseShape?.data?.description,
        tooltipTitle: houseShapeData?.houseShape?.data?.tooltip?.title,
        tooltipBody: houseShapeData?.houseShape?.data?.tooltip?.body,
        isActive: JSON.stringify(houseShapeData?.houseShape?.data?.isActive),
      };
      form?.setFieldsValue(dataToPass);
    }
  }, [houseShapeData]);

  const handleShowPrompt = () => {
    setIsDisabled(false);
    dispatch({
      type: 'SET_SHOW_PROMPT',
      data: true,
    });
  };

  const renderHeaderRightContent = () => (
    <>
      {isView ? (
        <Button className="common-button" size="middle" onClick={onEdit}>
          Edit
        </Button>
      ) : (
        <>
          <Button
            type="primary"
            className="common-button mr-16"
            size="middle"
            onClick={onCancelEdit}
          >
            Cancel
          </Button>
          <Button
            className="common-button"
            size="middle"
            onClick={form?.submit}
            loading={createLoading || updateLoading}
            disabled={createLoading || updateLoading || isDisabled}
          >
            Save
          </Button>
        </>
      )}
    </>
  );

  const houseShapeHeading = id ? `${mode} House Shape` : 'Add House Shape';

  const renderHeader = () => (
    <CommonTabHeader heading={houseShapeHeading}>
      {renderHeaderRightContent()}
    </CommonTabHeader>
  );

  const houseShapeCard = (
    <Card
      className={
        isOrganization
          ? 'full-height-without-nav-vertical-tabs'
          : 'full-height-without-nav'
      }
    >
      <HouseShapesForm
        form={form}
        onFinish={onFinish}
        handleShowPrompt={handleShowPrompt}
        isView={isView}
      />
    </Card>
  );

  return (
    <>
      <CommonHeader
        heading={isOrganization ? MODULES?.MASTER : houseShapeHeading}
        customLink={ROUTES?.MASTER}
      >
        {!isOrganization && <>{renderHeaderRightContent()}</>}
      </CommonHeader>
      {isOrganization ? (
        <OrganizationMastersTab>
          {renderHeader()}
          {houseShapeCard}
        </OrganizationMastersTab>
      ) : (
        houseShapeCard
      )}
    </>
  );
};

export default CreateHouseShapes;
