import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { ROUTES, TABS } from '@common/constants';

import CreateHeatingSystems from '../heatingSystems/pages/CreateHeatingSystems';
import HeatingSystems from '../heatingSystems/pages/HeatingSystems';
import CreateHouseShapes from '../houseShapes/pages/CreateHouseShapes';
import HouseShapes from '../houseShapes/pages/HouseShapes';
import CreateHouseTypes from '../houseTypes/pages/CreateHouseTypes';
import HouseTypes from '../houseTypes/pages/HouseTypes';
import CreateMaterialCategories from '../materialCategories/pages/CreateMaterialCategories';
import CreateMaterials from '../materialCategories/pages/CreateMaterials';
import MaterialCategories from '../materialCategories/pages/MaterialCategories';
import Materials from '../materialCategories/pages/Materials';
import CreateRoofExtensions from '../roofExtensions/pages/CreateRoofExtensions';
import RoofExtensions from '../roofExtensions/pages/RoofExtensions';
import CreateRoofTypes from '../roofTypes/pages/CreateRoofTypes';
import RoofTypes from '../roofTypes/pages/RoofTypes';
import './organizations.less';
import Organizations from './pages/Organization';
import OrganizationConfiguration from './pages/OrganizationConfigurations';
import OrganizationDetails from './pages/OrganizationDetails';
import OrganizationEmailConfig from './pages/OrganizationEmailConfig';
import OrganizationFieldConfig from './pages/OrganizationFieldConfig';
import OrganizationUiLabels from './pages/OrganizationUiLabels';

const OrganizationWrapper = () => (
  <Switch>
    <Route exact path={ROUTES?.ORGANIZATIONS} component={Organizations} />
    <Route
      exact
      path={`${ROUTES?.ORGANIZATIONS}/add/${TABS?.ORGANIZATION?.key}`}
      render={(props) => <OrganizationDetails {...props} />}
    />
    <Route
      exact
      path={`${ROUTES?.ORGANIZATIONS}/:orgMode/:orgId/${TABS?.ORGANIZATION?.key}`}
      render={(props) => <OrganizationDetails {...props} />}
    />
    <Route
      exact
      path={`${ROUTES?.ORGANIZATIONS}/:orgMode/:orgId/${TABS?.ORGANIZATION_CONFIGURATION?.key}`}
      render={(props) => <OrganizationConfiguration {...props} />}
    />
    <Route
      exact
      path={`${ROUTES?.ORGANIZATIONS}/:orgMode/:orgId/${TABS?.ORGANIZATION_UI_LABELS?.key}`}
      render={(props) => <OrganizationUiLabels {...props} />}
    />
    <Route
      exact
      path={`${ROUTES?.ORGANIZATIONS}/:orgMode/:orgId/${TABS?.ORGANIZATION_EMAIL_CONFIG?.key}`}
      render={(props) => <OrganizationEmailConfig {...props} />}
    />
    <Route
      exact
      path={`${ROUTES?.ORGANIZATIONS}/:orgMode/:orgId${ROUTES?.FIELD_CONFIG}`}
      render={(props) => <OrganizationFieldConfig {...props} />}
    />
    <Route
      exact
      path={`${ROUTES?.ORGANIZATIONS}/:orgMode/:orgId${ROUTES?.MASTER}`}
      render={() => (
        <Redirect
          to={`${ROUTES?.MASTER?.split('/')?.join('')}${ROUTES?.HOUSE_TYPES}`}
        />
      )}
    />
    <Route
      exact
      path={`${ROUTES?.ORGANIZATIONS}/:orgMode/:orgId${ROUTES?.MASTER}${ROUTES?.HOUSE_TYPES}`}
      render={(props) => <HouseTypes {...props} isOrganization />}
    />
    <Route
      exact
      path={`${ROUTES?.ORGANIZATIONS}/:orgMode/:orgId${ROUTES?.MASTER}${ROUTES?.HOUSE_TYPES}/add`}
      render={(props) => <CreateHouseTypes {...props} isOrganization />}
    />
    <Route
      exact
      path={`${ROUTES?.ORGANIZATIONS}/:orgMode/:orgId${ROUTES?.MASTER}${ROUTES?.HOUSE_TYPES}/:mode/:id`}
      render={(props) => <CreateHouseTypes {...props} isOrganization />}
    />
    <Route
      exact
      path={`${ROUTES?.ORGANIZATIONS}/:orgMode/:orgId${ROUTES?.MASTER}${ROUTES?.HOUSE_SHAPES}`}
      render={(props) => <HouseShapes {...props} isOrganization />}
    />
    <Route
      exact
      path={`${ROUTES?.ORGANIZATIONS}/:orgMode/:orgId${ROUTES?.MASTER}${ROUTES?.HOUSE_SHAPES}/add`}
      render={(props) => <CreateHouseShapes {...props} isOrganization />}
    />
    <Route
      exact
      path={`${ROUTES?.ORGANIZATIONS}/:orgMode/:orgId${ROUTES?.MASTER}${ROUTES?.HOUSE_SHAPES}/:mode/:id`}
      render={(props) => <CreateHouseShapes {...props} isOrganization />}
    />
    <Route
      exact
      path={`${ROUTES?.ORGANIZATIONS}/:orgMode/:orgId${ROUTES?.MASTER}${ROUTES?.ROOF_TYPES}`}
      render={(props) => <RoofTypes {...props} isOrganization />}
    />
    <Route
      exact
      path={`${ROUTES?.ORGANIZATIONS}/:orgMode/:orgId${ROUTES?.MASTER}${ROUTES?.ROOF_TYPES}/add`}
      render={(props) => <CreateRoofTypes {...props} isOrganization />}
    />
    <Route
      exact
      path={`${ROUTES?.ORGANIZATIONS}/:orgMode/:orgId${ROUTES?.MASTER}${ROUTES?.ROOF_TYPES}/:mode/:id`}
      render={(props) => <CreateRoofTypes {...props} isOrganization />}
    />
    <Route
      exact
      path={`${ROUTES?.ORGANIZATIONS}/:orgMode/:orgId${ROUTES?.MASTER}${ROUTES?.ROOF_EXTENSIONS}`}
      render={(props) => <RoofExtensions {...props} isOrganization />}
    />
    <Route
      exact
      path={`${ROUTES?.ORGANIZATIONS}/:orgMode/:orgId${ROUTES?.MASTER}${ROUTES?.ROOF_EXTENSIONS}/add`}
      render={(props) => <CreateRoofExtensions {...props} isOrganization />}
    />
    <Route
      exact
      path={`${ROUTES?.ORGANIZATIONS}/:orgMode/:orgId${ROUTES?.MASTER}${ROUTES?.ROOF_EXTENSIONS}/:mode/:id`}
      render={(props) => <CreateRoofExtensions {...props} isOrganization />}
    />
    <Route
      exact
      path={`${ROUTES?.ORGANIZATIONS}/:orgMode/:orgId${ROUTES?.MASTER}${ROUTES?.MATERIAL_CATEGORIES}`}
      render={(props) => <MaterialCategories {...props} isOrganization />}
    />
    <Route
      exact
      path={`${ROUTES?.ORGANIZATIONS}/:orgMode/:orgId${ROUTES?.MASTER}${ROUTES?.MATERIAL_CATEGORIES}/add`}
      render={(props) => <CreateMaterialCategories {...props} isOrganization />}
    />
    <Route
      exact
      path={`${ROUTES?.ORGANIZATIONS}/:orgMode/:orgId${ROUTES?.MASTER}${ROUTES?.MATERIAL_CATEGORIES}/:mode/:id`}
      render={(props) => <CreateMaterialCategories {...props} isOrganization />}
    />
    <Route
      exact
      path={`${ROUTES?.ORGANIZATIONS}/:orgMode/:orgId${ROUTES?.MASTER}${ROUTES?.MATERIAL_CATEGORIES}/:mode${ROUTES?.MATERIALS}/:id`}
      render={(props) => <Materials {...props} isOrganization />}
    />
    <Route
      exact
      path={`${ROUTES?.ORGANIZATIONS}/:orgMode/:orgId${ROUTES?.MASTER}${ROUTES?.MATERIAL_CATEGORIES}${ROUTES?.MATERIALS}/:id/add`}
      render={(props) => <CreateMaterials {...props} isOrganization />}
    />
    <Route
      exact
      path={`${ROUTES?.ORGANIZATIONS}/:orgMode/:orgId${ROUTES?.MASTER}${ROUTES?.MATERIAL_CATEGORIES}${ROUTES?.MATERIALS}/:id/:mode/:materialId`}
      render={(props) => <CreateMaterials {...props} isOrganization />}
    />
    <Route
      exact
      path={`${ROUTES?.ORGANIZATIONS}/:orgMode/:orgId${ROUTES?.MASTER}${ROUTES?.HEATING_SYSTEMS}`}
      render={(props) => <HeatingSystems {...props} isOrganization />}
    />
    <Route
      exact
      path={`${ROUTES?.ORGANIZATIONS}/:orgMode/:orgId${ROUTES?.MASTER}${ROUTES?.HEATING_SYSTEMS}/add`}
      render={(props) => <CreateHeatingSystems {...props} isOrganization />}
    />
    <Route
      exact
      path={`${ROUTES?.ORGANIZATIONS}/:orgMode/:orgId/${TABS?.MASTER?.key}${ROUTES?.HEATING_SYSTEMS}/:mode/:id`}
      render={(props) => <CreateHeatingSystems {...props} isOrganization />}
    />
  </Switch>
);

export default OrganizationWrapper;
