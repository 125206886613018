import React from 'react';

import { FilterFilled } from '@ant-design/icons';
import { Button, Image, Radio, Space, Tooltip } from 'antd';
import dayjs from 'dayjs';

import { ReactComponent as EditIcon } from '@assets/svg/pen-to-square-solid.svg';
import { ReactComponent as DeleteIcon } from '@assets/svg/trash-solid.svg';
import { BREAKPOINTS, DATE_FORMATS } from '@common/constants';
import CommonTable from '@components/CommonTable';
import SearchComponent from '@components/SearchComponent';

import history from '../../../historyData';

const materialCategoriesTable = ({
  baseRoute,
  paginationConfig,
  sortConfig,
  handleTableChange,
  materialCategoriesData = {},
  loading,
  onSearchChange,
  handleDeleteModalOpen,
  filterOption,
  setFilterOption,
  handleFilterApply,
  filterVisible,
  setFilterVisible,
}) => {
  const sortOrder = sortConfig?.sortBy === 'ASC' ? 'ascend' : 'descend';

  const handleFilterChange = (e) => {
    const { value, name } = e?.target;
    setFilterOption({ ...filterOption, [name]: value });
  };

  const renderFilter = (key) => (
    <div className="columnFilter" onKeyDown={(e) => e?.stopPropagation()}>
      <Space direction="vertical">
        <Radio.Group
          onChange={handleFilterChange}
          name={key}
          value={filterOption[key]}
        >
          <Space direction="vertical">
            <Radio value="true">True</Radio>
            <Radio value="false">False</Radio>
          </Space>
        </Radio.Group>
        <Space direction="horizontal">
          <Button size="small" onClick={handleFilterApply}>
            Apply
          </Button>
          <Button
            danger
            size="small"
            onClick={() => {
              setFilterOption({ ...filterOption, [key]: null });
            }}
          >
            Reset
          </Button>
        </Space>
      </Space>
    </div>
  );

  const getProjectFilterProps = (key) => ({
    filterDropdownOpen: filterVisible[key],
    onFilterDropdownOpenChange: (visible) =>
      setFilterVisible({ ...filterVisible, [key]: visible }),
    filterDropdown: () => renderFilter(key),
    filterIcon: () => (
      <FilterFilled
        className={filterOption[key] !== null ? 'filter-filled-data' : ''}
      />
    ),
  });

  const columns = [
    {
      title: 'KEY',
      dataIndex: 'key',
      key: 'key',
      ellipsis: true,
      width: 150,
      sorter: true,
      sortOrder: sortConfig?.sortOn === 'key' ? sortOrder : null,
      align: 'left',
      className: 'max-width-column',
      // eslint-disable-next-line no-undef
      fixed: window?.innerWidth > BREAKPOINTS?.desktop ? 'left' : false,
    },
    {
      title: 'LABEL',
      dataIndex: 'label',
      key: 'label',
      ellipsis: true,
      width: 150,
      sorter: true,
      sortOrder: sortConfig?.sortOn === 'label' ? sortOrder : null,
      align: 'left',
      className: 'max-width-column',
    },
    {
      title: 'IMAGE',
      dataIndex: 'imageUrl',
      key: 'imageUrl',
      width: 80,
      ellipsis: true,
      align: 'center',
      className: 'max-width-column',
      onCell: () => ({
        onClick: (e) => e?.stopPropagation(),
      }),
      render: (value) => <Image src={value} width={30} height={30} />,
    },
    {
      title: 'DESCRIPTION',
      dataIndex: 'description',
      key: 'description',
      ellipsis: true,
      width: 150,
      sorter: true,
      sortOrder: sortConfig?.sortOn === 'description' ? sortOrder : null,
      align: 'left',
      className: 'max-width-column',
      render: (value) => value ?? '-',
    },
    {
      title: 'TOOLTIP',
      dataIndex: 'tooltip',
      key: 'tooltip',
      ellipsis: true,
      width: 150,
      align: 'left',
      className: 'max-width-column',
      // eslint-disable-next-line no-console
      render: (value) => value?.title ?? '-',
    },
    {
      title: 'IS ACTIVE',
      dataIndex: 'isActive',
      key: 'isActive',
      ellipsis: true,
      width: 150,
      align: 'left',
      className: 'max-width-column',
      render: (value) => (value ? 'True' : 'False'),
      ...getProjectFilterProps('isActive'),
    },
    {
      title: 'REQUIRED',
      dataIndex: 'required',
      key: 'required',
      ellipsis: true,
      width: 150,
      align: 'left',
      className: 'max-width-column',
      render: (value) => (value ? 'True' : 'False'),
      ...getProjectFilterProps('required'),
    },
    {
      title: 'CREATED AT',
      dataIndex: 'createdAt',
      key: 'createdAt',
      ellipsis: true,
      width: 150,
      sorter: true,
      sortOrder: sortConfig?.sortOn === 'createdAt' ? sortOrder : null,
      align: 'left',
      className: 'max-width-column',
      render: (value) => dayjs(value)?.format(DATE_FORMATS?.REGULAR),
    },
    {
      title: 'ACTIONS',
      dataIndex: 'actions',
      key: 'actions',
      ellipsis: true,
      width: 150,
      // eslint-disable-next-line no-undef
      fixed: window?.innerWidth > BREAKPOINTS?.desktop ? 'right' : false,
      className: 'cursor-auto',
      onCell: () => ({
        onClick: (e) => e?.stopPropagation(),
      }),
      render: (_, value) => (
        <div className="action-button">
          <Tooltip title="Edit Material Category" placement="top" zIndex={4}>
            <EditIcon
              onClick={() => history?.push(`${baseRoute}/edit/${value?.id}`)}
              className="mr-16 pointer svg-icon"
            />
          </Tooltip>
          <Tooltip title="Delete Material Category" placement="top" zIndex={4}>
            <DeleteIcon
              onClick={() => handleDeleteModalOpen(value)}
              className="mr-16 pointer svg-icon"
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="d-flex justify-end mb-16 flex-wrap">
        <SearchComponent
          onChange={onSearchChange}
          className="search-box ml-16"
        />
      </div>
      <CommonTable
        columns={columns}
        data={materialCategoriesData?.data}
        onChange={handleTableChange}
        paginationConfig={{
          total: materialCategoriesData?.count,
          current: paginationConfig?.skip,
          pageSize: paginationConfig?.limit,
        }}
        loading={{ spinning: loading, size: 'default' }}
        rowKey={(record) => record?.id}
        rowClassName="pointer"
        onRow={(record) => ({
          onClick: () => history?.push(`${baseRoute}/view/${record?.id}`),
        })}
      />
    </>
  );
};

export default materialCategoriesTable;
