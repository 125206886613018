import { PlusOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Card, Modal } from 'antd';
import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { DEFAULT_SORT_CONFIG, MODULES, ROUTES } from '@common/constants';
import CommonHeader from '@components/CommonHeader';

import CommonTabHeader from '../../../components/CommonTabHeader';
import history from '../../../historyData';
import OrganizationMastersTab from '../../organizations/components/OrganizationMastersTab';
import MaterialCategoriesTable from '../components/MaterialCategoriesTable';
import { DELETE_MATERIAL_CATEGORY } from '../graphql/Mutations';
import { LIST_MATERIAL_CATEGORIES } from '../graphql/Queries';

const MaterialCategories = ({ isOrganization = false }) => {
  const { orgId, orgMode } = useParams();
  const [paginationConfig, setPaginationConfig] = useState({
    skip: 1,
    limit: 10,
  });
  const [sortConfig, setSortConfig] = useState(DEFAULT_SORT_CONFIG);
  const [filterOption, setFilterOption] = useState({
    isActive: null,
    required: null,
  });
  const [filterVisible, setFilterVisible] = useState({
    isActive: false,
    required: false,
  });
  const [search, setSearch] = useState('');
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [currentMaterialCategory, setCurrentMaterialCategory] = useState(null);

  const [
    listMaterialCategories,
    { data: materialCategoriesData, loading: dataLoading },
  ] = useLazyQuery(LIST_MATERIAL_CATEGORIES);

  const [deleteMaterialCategory, { loading: deleteLoading }] = useMutation(
    DELETE_MATERIAL_CATEGORY,
  );

  const getMaterialCategories = async () => {
    const { skip, limit } = paginationConfig;
    const { isActive, required } = filterOption;
    try {
      await listMaterialCategories({
        variables: {
          filter: {
            ...(isOrganization && { companyId: orgId }),
            skip: (skip - 1) * limit,
            limit,
            search,
            isActive: isActive !== null ? JSON.parse(isActive) : null,
            required: required !== null ? JSON.parse(required) : null,
          },
          sort: sortConfig?.sortOn ? [{ ...sortConfig }] : [],
        },
        fetchPolicy: 'network-only',
      });
    } catch (error) {
      return error;
    }
  };

  const handleFilterApply = () => {
    getMaterialCategories();
    setFilterVisible({ isActive: false, required: false });
  };

  useEffect(() => {
    const { isActive } = filterOption;
    if (isActive === null) {
      handleFilterApply();
    }
  }, [filterOption?.isActive]);

  useEffect(() => {
    const { required } = filterOption;
    if (required === null) {
      handleFilterApply();
    }
  }, [filterOption?.required]);

  useEffect(() => {
    getMaterialCategories();
  }, [paginationConfig, sortConfig, search]);

  const handleTableChange = (pagination, _filters, sorter) => {
    setPaginationConfig({
      skip: pagination?.current,
      limit: pagination?.pageSize,
    });
    setSortConfig({
      sortOn: sorter?.order ? sorter?.field : '',
      sortBy: sorter?.order === 'descend' ? 'DESC' : 'ASC',
    });
  };

  const onSearchChange = debounce((e) => {
    const { value } = e?.target;
    setPaginationConfig({ ...paginationConfig, skip: 1 });
    setSearch(value);
  }, 600);

  const handleDeleteModalOpen = (value) => {
    setIsDeleteOpen(true);
    setCurrentMaterialCategory(value);
  };

  const handleDeleteMaterialCategory = async (id) => {
    try {
      const res = await deleteMaterialCategory({
        variables: { where: { id } },
      });
      if (res?.data) {
        setCurrentMaterialCategory(null);
        setIsDeleteOpen(false);
        getMaterialCategories();
      }
    } catch (error) {
      return error;
    }
  };

  const baseRoute = `${
    isOrganization ? `${ROUTES?.ORGANIZATIONS}/${orgMode}/${orgId}` : ''
  }${ROUTES?.MASTER}${ROUTES?.MATERIAL_CATEGORIES}`;

  const renderHeaderRightContent = () => (
    <Button
      className="common-button"
      size="middle"
      icon={<PlusOutlined />}
      onClick={() => history?.push(`${baseRoute}/add`)}
    >
      Add Material Category
    </Button>
  );

  const renderHeader = () => (
    <CommonTabHeader
      heading={MODULES?.MATERIAL_CATEGORIES}
      isGoBackVisible={false}
    />
  );

  const materialCategoriesCard = (
    <Card
      className={
        isOrganization
          ? 'full-height-without-nav-vertical-tabs'
          : 'full-height-without-nav'
      }
    >
      <MaterialCategoriesTable
        baseRoute={baseRoute}
        handleTableChange={handleTableChange}
        paginationConfig={paginationConfig}
        sortConfig={sortConfig}
        materialCategoriesData={materialCategoriesData?.adminMaterialCategories}
        loading={dataLoading}
        onSearchChange={onSearchChange}
        handleDeleteModalOpen={handleDeleteModalOpen}
        handleFilterApply={handleFilterApply}
        filterVisible={filterVisible}
        setFilterVisible={setFilterVisible}
        filterOption={filterOption}
        setFilterOption={setFilterOption}
      />
    </Card>
  );

  return (
    <>
      <CommonHeader
        heading={
          isOrganization ? MODULES?.MASTER : MODULES?.MATERIAL_CATEGORIES
        }
      >
        {!isOrganization && <> {renderHeaderRightContent()}</>}
      </CommonHeader>
      {isOrganization ? (
        <OrganizationMastersTab>
          {renderHeader()}
          {materialCategoriesCard}
        </OrganizationMastersTab>
      ) : (
        materialCategoriesCard
      )}
      <Modal
        title="Delete the Material Category"
        okText="Yes"
        cancelText="No"
        open={isDeleteOpen}
        onOk={() => handleDeleteMaterialCategory(currentMaterialCategory?.id)}
        okButtonProps={{ loading: deleteLoading }}
        onCancel={() => {
          setIsDeleteOpen(false);
          setCurrentMaterialCategory(null);
        }}
      >
        Are you sure you want to delete this material category?
      </Modal>
    </>
  );
};

export default MaterialCategories;
