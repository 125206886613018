import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { ROUTES } from '@common/constants';

import './materialCategories.less';
import CreateMaterialCategories from './pages/CreateMaterialCategories';
import CreateMaterials from './pages/CreateMaterials';
import MaterialCategories from './pages/MaterialCategories';
import Materials from './pages/Materials';

const MaterialCategoriesWrapper = () => (
  <>
    <Switch>
      <Route
        exact
        path={`${ROUTES?.MASTER}${ROUTES?.MATERIAL_CATEGORIES}/:mode${ROUTES?.MATERIALS}/:id`}
        render={(props) => <Materials {...props} />}
      />
      <Route
        exact
        path={`${ROUTES?.MASTER}${ROUTES?.MATERIAL_CATEGORIES}${ROUTES?.MATERIALS}/:id/add`}
        render={(props) => <CreateMaterials {...props} />}
      />
      <Route
        exact
        path={`${ROUTES?.MASTER}${ROUTES?.MATERIAL_CATEGORIES}${ROUTES?.MATERIALS}/:id/:mode/:materialId`}
        render={(props) => <CreateMaterials {...props} />}
      />
      <Route
        exact
        path={`${ROUTES?.MASTER}${ROUTES?.MATERIAL_CATEGORIES}`}
        render={(props) => <MaterialCategories {...props} />}
      />
      <Route
        exact
        path={`${ROUTES?.MASTER}${ROUTES?.MATERIAL_CATEGORIES}/add`}
        render={(props) => <CreateMaterialCategories {...props} />}
      />
      <Route
        exact
        path={`${ROUTES?.MASTER}${ROUTES?.MATERIAL_CATEGORIES}/:mode/:id`}
        render={(props) => <CreateMaterialCategories {...props} />}
      />
    </Switch>
  </>
);

export default MaterialCategoriesWrapper;
