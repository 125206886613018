import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Card, Form } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { MODULES, ROUTES } from '@common/constants';
import CommonHeader from '@components/CommonHeader';
import { AppContext } from '@context';

import CommonTabHeader from '../../../components/CommonTabHeader';
import history from '../../../historyData';
import OrganizationMastersTab from '../../organizations/components/OrganizationMastersTab';
import HeatingSystemsForm from '../components/HeatingSystemsForm';
import {
  CREATE_HEATING_SYSTEM,
  UPDATE_HEATING_SYSTEM,
} from '../graphql/Mutations';
import { GET_HEATING_SYSTEM } from '../graphql/Queries';

const CreateHeatingSystems = ({ isOrganization = false }) => {
  const { mode, id, orgId, orgMode } = useParams();
  const [form] = Form?.useForm();
  const { dispatch } = useContext(AppContext);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isView, setIsView] = useState(false);

  const [createHeatingSystem, { loading: createLoading }] = useMutation(
    CREATE_HEATING_SYSTEM,
  );

  const [updateHeatingSystem, { loading: updateLoading }] = useMutation(
    UPDATE_HEATING_SYSTEM,
  );

  const [getHeatingSystems, { data: heatingSystemData }] = useLazyQuery(
    GET_HEATING_SYSTEM,
  );

  const fetchHeatingSystems = async () => {
    try {
      await getHeatingSystems({
        variables: { where: { id } },
        fetchPolicy: 'network-only',
      });
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    if (mode === 'view') {
      setIsView(true);
    }
    if (id) {
      fetchHeatingSystems();
    }
  }, []);

  const baseRoute = `${
    isOrganization ? `${ROUTES?.ORGANIZATIONS}/${orgMode}/${orgId}` : ''
  }${ROUTES?.MASTER}${ROUTES?.HEATING_SYSTEMS}`;

  const onEdit = () => {
    setIsView(false);
    history?.push(`${baseRoute}/edit/${id}`);
  };

  const onCancelEdit = () => {
    setIsView(true);
    history?.push(baseRoute);
  };

  const onFinish = async (values) => {
    dispatch({
      type: 'SET_SHOW_PROMPT',
      data: false,
    });

    const {
      key,
      label,
      description,
      isActive,
      tooltipTitle,
      tooltipBody,
    } = values;

    try {
      if (id) {
        try {
          await updateHeatingSystem({
            variables: {
              where: { id },
              data: {
                key,
                label,
                description,
                isActive: JSON.parse(isActive),
                tooltip: { title: tooltipTitle, body: tooltipBody },
              },
            },
            onCompleted: () => onCancelEdit(),
          });
        } catch (error) {
          return error;
        }
      } else {
        try {
          await createHeatingSystem({
            variables: {
              data: {
                key,
                label,
                description,
                isActive: JSON.parse(isActive),
                tooltip: { title: tooltipTitle, body: tooltipBody },
              },
            },
            onCompleted: () => onCancelEdit(),
          });
        } catch (error) {
          return error;
        }
      }
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    if (heatingSystemData?.heatingSystem?.data) {
      const dataToPass = {
        key: heatingSystemData?.heatingSystem?.data?.key,
        label: heatingSystemData?.heatingSystem?.data?.label,
        description: heatingSystemData?.heatingSystem?.data?.description,
        isActive: JSON.stringify(
          heatingSystemData?.heatingSystem?.data?.isActive,
        ),
        tooltipTitle: heatingSystemData?.heatingSystem?.data?.tooltip?.title,
        tooltipBody: heatingSystemData?.heatingSystem?.data?.tooltip?.body,
      };
      form?.setFieldsValue(dataToPass);
    }
  }, [heatingSystemData]);

  const handleShowPrompt = () => {
    setIsDisabled(false);
    dispatch({
      type: 'SET_SHOW_PROMPT',
      data: true,
    });
  };

  const renderHeaderRightContent = () => (
    <>
      {isView ? (
        <Button className="common-button" size="middle" onClick={onEdit}>
          Edit
        </Button>
      ) : (
        <>
          <Button
            type="primary"
            className="common-button mr-16"
            size="middle"
            onClick={onCancelEdit}
          >
            Cancel
          </Button>
          <Button
            className="common-button"
            size="middle"
            onClick={form?.submit}
            loading={createLoading || updateLoading}
            disabled={createLoading || updateLoading || isDisabled}
          >
            Save
          </Button>
        </>
      )}
    </>
  );

  const heatingSystemHeading = id
    ? `${mode} Heating System`
    : 'Add Heating System';

  const renderHeader = () => (
    <CommonTabHeader heading={heatingSystemHeading}>
      {renderHeaderRightContent()}
    </CommonTabHeader>
  );

  const heatingSystemCard = (
    <Card
      className={
        isOrganization
          ? 'full-height-without-nav-vertical-tabs'
          : 'full-height-without-nav'
      }
    >
      <HeatingSystemsForm
        form={form}
        onFinish={onFinish}
        handleShowPrompt={handleShowPrompt}
        isView={isView}
      />
    </Card>
  );

  return (
    <>
      <CommonHeader
        heading={isOrganization ? MODULES?.MASTER : heatingSystemHeading}
        customLink={ROUTES?.MASTER}
      >
        {!isOrganization && <>{renderHeaderRightContent()}</>}
      </CommonHeader>
      {isOrganization ? (
        <OrganizationMastersTab>
          {renderHeader()}
          {heatingSystemCard}
        </OrganizationMastersTab>
      ) : (
        heatingSystemCard
      )}
    </>
  );
};

export default CreateHeatingSystems;
