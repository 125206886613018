import { useLazyQuery, useMutation } from '@apollo/client';
import { Tooltip } from 'antd';
import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ROUTES } from '@common/constants';
import CommonHeader from '@components/CommonHeader';

import { ReactComponent as ReloadIcon } from '../../../assets/svg/rotate-right-solid.svg';
import OrganizationTab from '../components/OrganizationTab';
import OrganizationUiLabelModal from '../components/OrganizationUiLabelModal';
import OrganizationUiLabelsTable from '../components/OrganizationUiLabelsTable';
import { REFETCH_UI_LABELS } from '../graphql/Mutations';
import { LIST_UI_LABELS } from '../graphql/Queries';

const OrganizationUiLabels = () => {
  const { orgId } = useParams();
  const [paginationConfig, setPaginationConfig] = useState({
    skip: 1,
    limit: 10,
  });
  const [search, setSearch] = useState('');
  const [languageFilter, setLanguageFilter] = useState('en');
  const [isEditModal, setIsEditModal] = useState(false);
  const [uiLabelEdit, setUiLabelEdit] = useState(null);

  const [
    listUiLabels,
    { data: uiLabelsData, loading: uiLabelsLoading },
  ] = useLazyQuery(LIST_UI_LABELS);

  const [refetchUiLabels] = useMutation(REFETCH_UI_LABELS);

  const getUiLabels = async () => {
    const { skip, limit } = paginationConfig;
    try {
      await listUiLabels({
        variables: {
          where: {
            languageCode: languageFilter,
            companyId: orgId,
          },
          filter: {
            skip: (skip - 1) * limit,
            limit,
            search,
          },
        },
        fetchPolicy: 'network-only',
      });
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    getUiLabels();
  }, [languageFilter, paginationConfig, search]);

  const onSearchChange = debounce((e) => {
    const { value } = e?.target;
    setPaginationConfig({ ...paginationConfig, skip: 1 });
    setSearch(value);
  }, 600);

  const handleFormClose = () => {
    setIsEditModal(false);
    setUiLabelEdit(null);
  };

  const handleTableChange = (pagination) => {
    setPaginationConfig({
      skip: pagination?.current,
      limit: pagination?.pageSize,
    });
  };

  const onRefetchClick = async () => {
    try {
      await refetchUiLabels({
        variables: { data: { companyId: orgId } },
      });
    } catch (error) {
      return error;
    }
  };

  return (
    <>
      <CommonHeader
        heading="Organisation Ui Labels"
        customLink={ROUTES?.ORGANIZATIONS}
      >
        <Tooltip title="Revalidate Cache" placement="left">
          <ReloadIcon
            className="ui-label-reload-icon"
            onClick={onRefetchClick}
            height={20}
            width={20}
          />
        </Tooltip>
      </CommonHeader>
      <OrganizationTab>
        <div className="tab-table">
          <OrganizationUiLabelsTable
            handleTableChange={handleTableChange}
            setIsEditModal={setIsEditModal}
            setUiLabelEdit={setUiLabelEdit}
            onSearchChange={onSearchChange}
            uiLabelsLoading={uiLabelsLoading}
            uiLabelsData={uiLabelsData?.getUILabels}
            paginationConfig={paginationConfig}
            languageFilter={languageFilter}
            setLanguageFilter={setLanguageFilter}
          />
        </div>
      </OrganizationTab>
      <OrganizationUiLabelModal
        open={isEditModal}
        handleFormClose={handleFormClose}
        uiLabelEdit={uiLabelEdit}
        getUiLabels={getUiLabels}
      />
    </>
  );
};

export default OrganizationUiLabels;
