import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Card, Form } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { MODULES, ROUTES } from '@common/constants';
import CommonHeader from '@components/CommonHeader';
import { AppContext } from '@context';

import CommonTabHeader from '../../../components/CommonTabHeader';
import history from '../../../historyData';
import OrganizationMastersTab from '../../organizations/components/OrganizationMastersTab';
import HouseTypesForm from '../components/HouseTypesForm';
import { CREATE_HOUSE_TYPE, UPDATE_HOUSE_TYPE } from '../graphql/Mutations';
import { GET_HOUSE_TYPES } from '../graphql/Queries';

const CreateHouseTypes = ({ isOrganization = false }) => {
  const { mode, id, orgId, orgMode } = useParams();
  const [form] = Form?.useForm();
  const { dispatch } = useContext(AppContext);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isView, setIsView] = useState(false);

  const [createHouseType, { loading: createLoading }] = useMutation(
    CREATE_HOUSE_TYPE,
  );

  const [updateHouseType, { loading: updateLoading }] = useMutation(
    UPDATE_HOUSE_TYPE,
  );

  const [getHouseType, { data: houseTypeData }] = useLazyQuery(GET_HOUSE_TYPES);

  const fetchHouseType = async () => {
    try {
      await getHouseType({
        variables: { where: { id } },
        fetchPolicy: 'network-only',
      });
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    if (mode === 'view') {
      setIsView(true);
    }
    if (id) {
      fetchHouseType();
    }
  }, []);

  const baseRoute = `${
    isOrganization ? `${ROUTES?.ORGANIZATIONS}/${orgMode}/${orgId}` : ''
  }${ROUTES?.MASTER}${ROUTES?.HOUSE_TYPES}`;

  const onEdit = () => {
    setIsView(false);
    history?.push(`${baseRoute}/edit/${id}`);
  };

  const onCancelEdit = () => {
    setIsView(true);
    history?.push(baseRoute);
  };

  const onFinish = async (values) => {
    dispatch({
      type: 'SET_SHOW_PROMPT',
      data: false,
    });

    const {
      key,
      label,
      description,
      isActive,
      tooltipTitle,
      tooltipBody,
    } = values;

    try {
      if (id) {
        try {
          await updateHouseType({
            variables: {
              where: { id },
              data: {
                key,
                label,
                description,
                isActive: JSON.parse(isActive),
                tooltip: { title: tooltipTitle, body: tooltipBody },
              },
            },
            onCompleted: () => onCancelEdit(),
          });
        } catch (error) {
          return error;
        }
      } else {
        try {
          await createHouseType({
            variables: {
              data: {
                key,
                label,
                description,
                isActive: JSON.parse(isActive),
                tooltip: { title: tooltipTitle, body: tooltipBody },
              },
            },
            onCompleted: () => onCancelEdit(),
          });
        } catch (error) {
          return error;
        }
      }
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    if (houseTypeData?.houseType?.data) {
      const dataToPass = {
        key: houseTypeData?.houseType?.data?.key,
        label: houseTypeData?.houseType?.data?.label,
        description: houseTypeData?.houseType?.data?.description,
        tooltipTitle: houseTypeData?.houseType?.data?.tooltip?.title,
        tooltipBody: houseTypeData?.houseType?.data?.tooltip?.body,
        isActive: JSON.stringify(houseTypeData?.houseType?.data?.isActive),
      };
      form?.setFieldsValue(dataToPass);
    }
  }, [houseTypeData]);

  const handleShowPrompt = () => {
    setIsDisabled(false);
    dispatch({
      type: 'SET_SHOW_PROMPT',
      data: true,
    });
  };

  const renderHeaderRightContent = () => (
    <>
      {isView ? (
        <Button className="common-button" size="middle" onClick={onEdit}>
          Edit
        </Button>
      ) : (
        <>
          <Button
            type="primary"
            className="common-button mr-16"
            size="middle"
            onClick={onCancelEdit}
          >
            Cancel
          </Button>
          <Button
            className="common-button"
            size="middle"
            onClick={form?.submit}
            loading={createLoading || updateLoading}
            disabled={createLoading || updateLoading || isDisabled}
          >
            Save
          </Button>
        </>
      )}
    </>
  );

  const houseTypeHeading = id ? `${mode} House Type` : 'Add House Type';

  const renderHeader = () => (
    <CommonTabHeader heading={houseTypeHeading}>
      {renderHeaderRightContent()}
    </CommonTabHeader>
  );

  const houseTypesCard = (
    <Card
      className={
        isOrganization
          ? 'full-height-without-nav-vertical-tabs'
          : 'full-height-without-nav'
      }
    >
      <HouseTypesForm
        form={form}
        onFinish={onFinish}
        handleShowPrompt={handleShowPrompt}
        isView={isView}
      />
    </Card>
  );

  return (
    <>
      <CommonHeader
        heading={isOrganization ? MODULES?.MASTER : houseTypeHeading}
        customLink={ROUTES?.MASTER}
      >
        {!isOrganization && <>{renderHeaderRightContent()}</>}
      </CommonHeader>
      {isOrganization ? (
        <OrganizationMastersTab>
          {renderHeader()}
          {houseTypesCard}
        </OrganizationMastersTab>
      ) : (
        houseTypesCard
      )}
    </>
  );
};

export default CreateHouseTypes;
