import React from 'react';

import { Col, Row } from 'antd';

import GoBackButton from '@components/GoBackButton';

const CommonTabHeader = ({
  heading = '',
  children,
  customLink = '/',
  isGoBackVisible = true,
}) => (
  <Row justify="space-between" align="middle" className="mb-16">
    <Col className="d-flex align-center">
      {isGoBackVisible && <GoBackButton customLink={customLink} />}
      <h2 className="text-capitalize">{heading}</h2>
    </Col>
    <Col>{children}</Col>
  </Row>
);

export default CommonTabHeader;
