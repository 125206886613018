import React, { useEffect, useState } from 'react';

import { Tabs } from 'antd';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { MASTERS_TABS, ROUTES } from '../../../common/constants';
import OrganizationTab from './OrganizationTab';

const OrganizationMastersTab = ({ children }) => {
  const location = useLocation();
  const history = useHistory();
  const { orgMode, orgId } = useParams();
  const tabKeys = Object?.values(MASTERS_TABS)?.map((tab) => tab?.key);
  const [activeTabKey, setActiveTabKey] = useState(tabKeys?.[0]);

  useEffect(() => {
    const foundTab = tabKeys?.find((key) => location?.pathname?.includes(key));
    if (foundTab) {
      setActiveTabKey(foundTab);
    }
  }, [location, tabKeys]);

  const tabList = Object?.values(MASTERS_TABS)?.map((tab) => ({
    key: tab?.key,
    label: tab?.label,
    children,
  }));

  const handleTabClick = (key) => {
    setActiveTabKey(key);
    history?.push(
      `${ROUTES?.ORGANIZATIONS}/${orgMode}/${orgId}${ROUTES?.MASTER}/${key}`,
    );
  };

  return (
    <OrganizationTab>
      <Tabs
        tabPosition="left"
        activeKey={activeTabKey}
        items={tabList?.map((tab) => ({
          ...tab,
          label: (
            <span onClick={() => handleTabClick(tab?.key)}>{tab?.label}</span>
          ),
        }))}
        className="nested-tab-inside-tab"
        onChange={(key) => handleTabClick(key)}
      />
    </OrganizationTab>
  );
};

export default OrganizationMastersTab;
