import { Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { MASTERS_TABS, MATERIAL_TABS, ROUTES, TABS } from '@common/constants';

const MaterialTab = ({ isOrganization = false, children }) => {
  const location = useLocation();
  const history = useHistory();
  const { id, mode, orgMode, orgId } = useParams();

  const [activeTabKey, setActiveTabKey] = useState('details');

  const baseRoute = isOrganization
    ? `${ROUTES?.ORGANIZATIONS}/${orgMode}/${orgId}/${TABS?.MASTER?.key}/${MASTERS_TABS?.MATERIAL_CATEGORIES?.key}`
    : `${ROUTES?.MASTER}${ROUTES?.MATERIAL_CATEGORIES}`;

  const onTabChange = (key) => {
    setActiveTabKey(key);
    if (key === 'materials') {
      history?.push(`${baseRoute}/${mode}${ROUTES?.MATERIALS}/${id}`);
    }
    if (key === 'material-categories') {
      history?.push(`${baseRoute}/${mode}/${id}`);
    }
  };

  useEffect(() => {
    if (location?.pathname?.includes(MATERIAL_TABS?.MATERIAL_CATEGORIES?.key)) {
      setActiveTabKey(MATERIAL_TABS?.MATERIAL_CATEGORIES?.key);
    }
    if (
      location?.pathname?.includes(
        `${MATERIAL_TABS?.MATERIAL_CATEGORIES?.key}/${mode}/${MATERIAL_TABS?.MATERIALS?.key}`,
      )
    ) {
      setActiveTabKey(MATERIAL_TABS?.MATERIALS?.key);
    }
  }, [location]);

  const tabList = [
    {
      key: MATERIAL_TABS?.MATERIAL_CATEGORIES?.key,
      label: MATERIAL_TABS?.MATERIAL_CATEGORIES?.label,
      children,
    },
    {
      key: MATERIAL_TABS?.MATERIALS?.key,
      label: MATERIAL_TABS?.MATERIALS?.label,
      children,
    },
  ];

  return (
    <div className={isOrganization ? 'tab-card-container' : 'card-container'}>
      <Tabs
        activeKey={activeTabKey}
        type="card"
        className="full-width"
        onChange={(key) => onTabChange(key)}
        items={tabList}
      />
    </div>
  );
};

export default MaterialTab;
