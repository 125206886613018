import { MenuOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { DndContext } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  SortableContext,
  arrayMove,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Form, Modal, Switch, Table, Tooltip } from 'antd';
import { map } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ReactComponent as EditIcon } from '@assets/svg/pen-to-square-solid.svg';

import {
  BREAKPOINTS,
  CONFIG_DURATION_OPTIONS,
} from '../../../common/constants';
import SelectComponent from '../../../components/SelectComponent';
import { UPDATE_ORGANIZATION_CONFIG } from '../graphql/Mutations';
import { GET_ORGANIZATION_RECOMMENDATION_FIELD_CONFIG } from '../graphql/Queries';
import ConfigModal from './ConfigModal';

const Row = ({ children, ...props }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props['data-row-key'],
  });
  const style = {
    ...props.style,
    transform: CSS.Transform.toString(
      transform && {
        ...transform,
        scaleY: 1,
      },
    ),
    transition,
    ...(isDragging
      ? {
          position: 'relative',
          zIndex: 9999,
        }
      : {}),
  };
  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, (child) => {
        if (child.key === 'sort') {
          return React.cloneElement(child, {
            children: (
              <MenuOutlined
                ref={setActivatorNodeRef}
                style={{
                  touchAction: 'none',
                  cursor: 'move',
                }}
                {...listeners}
              />
            ),
          });
        }
        return child;
      })}
    </tr>
  );
};

const RecommendationConfigTable = () => {
  const [form] = Form.useForm();
  const { orgId } = useParams();
  const [organizationDetails, setOrganizationDetails] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [organizationConfig, setOrganizationConfig] = useState({});
  const [isConfigModalOpen, setIsConfigModalOpen] = useState(false);
  const [isConfigBtnLoading, setIsConfigBtnLoading] = useState(false);
  const [isVisibilityLoading, setIsVisibilityLoading] = useState(false);
  const [duration, setDuration] = useState('');
  const [isDurationPrompts, setIsDurationPrompts] = useState(false);
  const [isDurationLoading, setIsDurationLoading] = useState(false);

  const [getCompanyConfig] = useLazyQuery(
    GET_ORGANIZATION_RECOMMENDATION_FIELD_CONFIG,
    {
      onCompleted: (response) => {
        setOrganizationDetails(response?.getCompanyConfig);
        setIsLoading(false);
      },
      fetchPolicy: 'network-only',
      onError() {},
    },
  );

  useEffect(() => {
    setIsLoading(true);
    getCompanyConfig({
      variables: { where: { companyId: orgId } },
    });
  }, []);

  const data = organizationDetails?.result?.map(
    ({ __typename, ...rest }) => rest,
  );

  const [updateOrganizationConfig] = useMutation(UPDATE_ORGANIZATION_CONFIG, {
    onError: () => {},
  });

  const onChange = async (checked, record) => {
    setIsVisibilityLoading(true);
    const updatedConfig = { ...record, visibility: checked };
    const updatedArray = data?.map((item) => {
      if (item?.key === updatedConfig?.key) {
        return updatedConfig;
      }
      return item;
    });
    const res = await updateOrganizationConfig({
      variables: {
        where: { id: organizationDetails?.id },
        data: {
          result: updatedArray,
        },
      },
    });
    setIsVisibilityLoading(false);
    if (res?.data) {
      setIsLoading(true);
      getCompanyConfig({
        variables: { where: { companyId: orgId } },
      });
    }
  };

  const handleDuration = async () => {
    setIsDurationLoading(true);
    const updatedConfig = { ...organizationConfig, timePeriod: duration };
    const updatedArray = data?.map((item) => {
      if (item?.key === updatedConfig?.key) {
        return updatedConfig;
      }
      return item;
    });
    const res = await updateOrganizationConfig({
      variables: {
        where: { id: organizationDetails?.id },
        data: {
          result: updatedArray,
        },
      },
    });
    if (res?.data) {
      setIsLoading(true);
      setIsDurationPrompts(false);
      getCompanyConfig({
        variables: { where: { companyId: orgId } },
      });
    }
    setIsDurationLoading(false);
  };

  const onFinish = async (values) => {
    setIsConfigBtnLoading(true);
    const labelUpdatedArray = data?.map((item) => {
      if (item?.key === organizationConfig?.key) {
        return { ...organizationConfig, label: values?.label };
      }
      return item;
    });
    const res = await updateOrganizationConfig({
      variables: {
        where: { id: organizationDetails?.id },
        data: {
          result: labelUpdatedArray,
        },
      },
    });
    setIsConfigBtnLoading(false);
    if (res?.data) {
      form?.resetFields();
      setIsConfigModalOpen(false);
      setIsLoading(true);
      getCompanyConfig({
        variables: { where: { companyId: orgId } },
      });
    }
  };

  const handleDurationChange = (value, record) => {
    setOrganizationConfig(record);
    setDuration(value);
    setIsDurationPrompts(true);
  };

  const columns = [
    {
      key: 'sort',
      width: 50,
      // eslint-disable-next-line no-undef
      fixed: window?.innerWidth > BREAKPOINTS?.desktop ? 'left' : false,
    },
    {
      title: 'KEY',
      dataIndex: 'key',
      width: 250,
      ellipsis: true,
      align: 'left',
      className: 'max-width-column',
      // eslint-disable-next-line no-undef
      fixed: window?.innerWidth > BREAKPOINTS?.desktop ? 'left' : false,
    },
    {
      title: 'LABEL',
      dataIndex: 'label',
      width: 250,
      ellipsis: true,
      align: 'left',
      className: 'max-width-column',
    },
    {
      title: 'VISIBILITY',
      dataIndex: 'visibility',
      width: 100,
      ellipsis: true,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => (
        <Switch
          defaultChecked={record?.visibility}
          size="small"
          onChange={(checked) => onChange(checked, record)}
          disabled={isVisibilityLoading}
        />
      ),
    },
    {
      title: 'DURATION',
      dataIndex: 'duration',
      key: 'duration',
      width: 150,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => (
        <SelectComponent
          size="small"
          className="duration-select"
          placeholder="Select Duration"
          showAction={['click']}
          value={record?.timePeriod}
          showSearch={false}
          options={map(CONFIG_DURATION_OPTIONS, (config) => config)}
          onChange={(value) => handleDurationChange(value, record)}
          allowClear={false}
        />
      ),
    },
    {
      title: 'ACTIONS',
      dataIndex: 'actions',
      width: 150,
      ellipsis: true,
      align: 'left',
      className: 'max-width-column',
      // eslint-disable-next-line no-undef
      fixed: window?.innerWidth > BREAKPOINTS?.desktop ? 'right' : false,
      render: (_, record) => (
        <div className="action-button">
          <Tooltip title="Edit Ui Label" placement="top" zIndex={4}>
            <EditIcon
              className="mr-16 pointer svg-icon"
              onClick={() => {
                setOrganizationConfig(record);
                setIsConfigModalOpen(true);
              }}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  const onDragEnd = async ({ active, over }) => {
    if (active.id !== over?.id) {
      const activeIndex = (data || [])?.findIndex((i) => i?.key === active?.id);
      const overIndex = (data || [])?.findIndex((i) => i?.key === over?.id);
      const res = await updateOrganizationConfig({
        variables: {
          where: { id: organizationDetails?.id },
          data: {
            result: arrayMove(
              data || [],
              activeIndex,
              overIndex,
            )?.map((item, index) => ({ ...item, order: index + 1 })),
          },
        },
      });
      if (res?.data) {
        setIsLoading(true);
        getCompanyConfig({
          variables: { where: { companyId: orgId } },
        });
      }
    }
  };

  return (
    <div>
      <Modal
        title="Caution"
        okText="Yes"
        cancelText="No"
        open={isDurationPrompts}
        onOk={handleDuration}
        onCancel={() => setIsDurationPrompts(false)}
        okButtonProps={{ loading: isDurationLoading }}
      >
        Are you sure you want to change duration of {organizationConfig?.key} to
        <strong> {CONFIG_DURATION_OPTIONS?.[duration]?.label} </strong>?
      </Modal>
      <ConfigModal
        form={form}
        loadings={isConfigBtnLoading}
        isModalOpen={isConfigModalOpen}
        setIsModalOpen={setIsConfigModalOpen}
        organizationConfig={organizationConfig}
        onFinish={onFinish}
      />
      <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
        <SortableContext
          items={(data || [])?.map((i) => i?.key)}
          strategy={verticalListSortingStrategy}
        >
          <Table
            components={{
              body: {
                row: Row,
              },
            }}
            rowKey={(record) => record?.key}
            columns={columns}
            dataSource={data || []}
            loading={isLoading}
            pagination={false}
          />
        </SortableContext>
      </DndContext>
    </div>
  );
};

export default RecommendationConfigTable;
