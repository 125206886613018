import { Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { ROUTES, TABS } from '@common/constants';

const OrganizationTab = ({ children }) => {
  const location = useLocation();
  const history = useHistory();
  const { orgId, orgMode } = useParams();

  const [activeTabKey, setActiveTabKey] = useState('details');

  const onTabChange = (key) => {
    setActiveTabKey(key);
    if (location?.pathname?.includes('add')) {
      history?.push(`${ROUTES?.ORGANIZATIONS}/add/${key}`);
    } else {
      history?.push(`${ROUTES?.ORGANIZATIONS}/${orgMode}/${orgId}/${key}`);
    }
  };

  useEffect(() => {
    if (location?.pathname?.includes(TABS?.ORGANIZATION?.key)) {
      setActiveTabKey(TABS?.ORGANIZATION?.key);
    }
    if (location?.pathname?.includes(TABS?.ORGANIZATION_CONFIGURATION?.key)) {
      setActiveTabKey(TABS?.ORGANIZATION_CONFIGURATION?.key);
    }
    if (location?.pathname?.includes(TABS?.ORGANIZATION_EMAIL_CONFIG?.key)) {
      setActiveTabKey(TABS?.ORGANIZATION_EMAIL_CONFIG?.key);
    }
    if (location?.pathname?.includes(TABS?.ORGANIZATION_UI_LABELS?.key)) {
      setActiveTabKey(TABS?.ORGANIZATION_UI_LABELS?.key);
    }
    if (location?.pathname?.includes(TABS?.FIELD_CONFIG?.key)) {
      setActiveTabKey(TABS?.FIELD_CONFIG?.key);
    }
    if (location?.pathname?.includes(TABS?.MASTER?.key)) {
      setActiveTabKey(TABS?.MASTER?.key);
    }
  }, [location]);

  const tabList = [
    {
      key: TABS?.ORGANIZATION?.key,
      label: TABS?.ORGANIZATION?.label,
      children,
    },
    {
      key: TABS?.ORGANIZATION_CONFIGURATION?.key,
      label: TABS?.ORGANIZATION_CONFIGURATION?.label,
      children,
    },
    {
      key: TABS?.ORGANIZATION_UI_LABELS?.key,
      label: TABS?.ORGANIZATION_UI_LABELS?.label,
      children,
    },
    {
      key: TABS?.ORGANIZATION_EMAIL_CONFIG?.key,
      label: TABS?.ORGANIZATION_EMAIL_CONFIG?.label,
      children,
    },
    {
      key: TABS?.FIELD_CONFIG?.key,
      label: TABS?.FIELD_CONFIG?.label,
      children,
    },
    {
      key: TABS?.MASTER?.key,
      label: TABS?.MASTER?.label,
      children,
    },
  ];

  const filteredTabList = orgId
    ? tabList
    : tabList?.filter((item) => item?.key === TABS?.ORGANIZATION?.key);

  return (
    <div className="card-container">
      <Tabs
        activeKey={activeTabKey}
        type="card"
        className="full-width"
        onChange={(key) => onTabChange(key)}
        items={filteredTabList}
        destroyInactiveTabPane
      />
    </div>
  );
};

export default OrganizationTab;
