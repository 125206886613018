import { PlusOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Card, Modal } from 'antd';
import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { DEFAULT_SORT_CONFIG, MODULES, ROUTES } from '@common/constants';
import CommonHeader from '@components/CommonHeader';

import CommonTabHeader from '../../../components/CommonTabHeader';
import history from '../../../historyData';
import OrganizationMastersTab from '../../organizations/components/OrganizationMastersTab';
import HouseShapesTable from '../components/HouseShapesTable';
import { DELETE_HOUSE_SHAPE } from '../graphql/Mutations';
import { LIST_HOUSE_SHAPES } from '../graphql/Queries';

const HouseShapes = ({ isOrganization = false }) => {
  const { orgId, orgMode } = useParams();
  const [paginationConfig, setPaginationConfig] = useState({
    skip: 1,
    limit: 10,
  });
  const [sortConfig, setSortConfig] = useState(DEFAULT_SORT_CONFIG);
  const [isActiveFilter, setIsActiveFilter] = useState(null);
  const [filterVisible, setFilterVisible] = useState(false);
  const [search, setSearch] = useState('');
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [currentHouseShape, setCurrentHouseShape] = useState(null);

  const [
    listHouseShapes,
    { data: houseShapesData, loading: dataLoading },
  ] = useLazyQuery(LIST_HOUSE_SHAPES);

  const [deleteHouseShape, { loading: deleteLoading }] = useMutation(
    DELETE_HOUSE_SHAPE,
  );

  const getHouseShapes = async () => {
    const { skip, limit } = paginationConfig;
    try {
      await listHouseShapes({
        variables: {
          filter: {
            ...(isOrganization && { companyId: orgId }),
            skip: (skip - 1) * limit,
            limit,
            search,
            isActive:
              isActiveFilter !== null ? JSON.parse(isActiveFilter) : null,
          },
          sort: sortConfig?.sortOn ? [{ ...sortConfig }] : [],
        },
        fetchPolicy: 'network-only',
      });
    } catch (error) {
      return error;
    }
  };

  const handleFilterApply = () => {
    getHouseShapes();
    setFilterVisible(false);
  };

  useEffect(() => {
    if (isActiveFilter === null) {
      handleFilterApply();
    }
  }, [isActiveFilter]);

  useEffect(() => {
    getHouseShapes();
  }, [paginationConfig, sortConfig, search]);

  const handleTableChange = (pagination, _filters, sorter) => {
    setPaginationConfig({
      skip: pagination?.current,
      limit: pagination?.pageSize,
    });
    setSortConfig({
      sortOn: sorter?.order ? sorter?.field : '',
      sortBy: sorter?.order === 'descend' ? 'DESC' : 'ASC',
    });
  };

  const onSearchChange = debounce((e) => {
    const { value } = e?.target;
    setPaginationConfig({ ...paginationConfig, skip: 1 });
    setSearch(value);
  }, 600);

  const handleDeleteModalOpen = (value) => {
    setIsDeleteOpen(true);
    setCurrentHouseShape(value);
  };

  const handledeleteHouseShape = async (id) => {
    try {
      const res = await deleteHouseShape({ variables: { where: { id } } });
      if (res?.data) {
        setCurrentHouseShape(null);
        setIsDeleteOpen(false);
        getHouseShapes();
      }
    } catch (error) {
      return error;
    }
  };

  const baseRoute = `${
    isOrganization ? `${ROUTES?.ORGANIZATIONS}/${orgMode}/${orgId}` : ''
  }${ROUTES?.MASTER}${ROUTES?.HOUSE_SHAPES}`;

  const renderHeaderRightContent = () => (
    <Button
      className="common-button"
      size="middle"
      icon={<PlusOutlined />}
      onClick={() => history?.push(`${baseRoute}/add`)}
    >
      Add House Shape
    </Button>
  );

  const renderHeader = () => (
    <CommonTabHeader heading={MODULES?.HOUSE_SHAPES} isGoBackVisible={false} />
  );

  const houseShapesCard = (
    <Card
      className={
        isOrganization
          ? 'full-height-without-nav-vertical-tabs'
          : 'full-height-without-nav'
      }
    >
      <HouseShapesTable
        baseRoute={baseRoute}
        handleTableChange={handleTableChange}
        paginationConfig={paginationConfig}
        sortConfig={sortConfig}
        houseShapesData={houseShapesData?.adminHouseShapes}
        loading={dataLoading}
        onSearchChange={onSearchChange}
        handleDeleteModalOpen={handleDeleteModalOpen}
        isActiveFilter={isActiveFilter}
        setIsActiveFilter={setIsActiveFilter}
        handleFilterApply={handleFilterApply}
        filterVisible={filterVisible}
        setFilterVisible={setFilterVisible}
      />
    </Card>
  );

  return (
    <>
      <CommonHeader
        heading={isOrganization ? MODULES?.MASTER : MODULES?.HOUSE_SHAPES}
      >
        {!isOrganization && <>{renderHeaderRightContent()}</>}
      </CommonHeader>
      {isOrganization ? (
        <OrganizationMastersTab>
          {renderHeader()}
          {houseShapesCard}
        </OrganizationMastersTab>
      ) : (
        houseShapesCard
      )}
      <Modal
        title="Delete the House Shape"
        okText="Yes"
        cancelText="No"
        open={isDeleteOpen}
        onOk={() => handledeleteHouseShape(currentHouseShape?.id)}
        okButtonProps={{ loading: deleteLoading }}
        onCancel={() => {
          setIsDeleteOpen(false);
          setCurrentHouseShape(null);
        }}
      >
        Are you sure you want to delete this house shape?
      </Modal>
    </>
  );
};

export default HouseShapes;
