import { PlusOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Card, Modal } from 'antd';
import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { DEFAULT_SORT_CONFIG, MODULES, ROUTES } from '@common/constants';
import CommonHeader from '@components/CommonHeader';

import CommonTabHeader from '../../../components/CommonTabHeader';
import history from '../../../historyData';
import OrganizationMastersTab from '../../organizations/components/OrganizationMastersTab';
import HeatingSystemsTable from '../components/HeatingSystemsTable';
import { DELETE_HEATING_SYSTEM } from '../graphql/Mutations';
import { LIST_HEATING_SYSTEMS } from '../graphql/Queries';

const HeatingSystems = ({ isOrganization = false }) => {
  const { orgId, orgMode } = useParams();
  const [paginationConfig, setPaginationConfig] = useState({
    skip: 1,
    limit: 10,
  });
  const [sortConfig, setSortConfig] = useState(DEFAULT_SORT_CONFIG);
  const [isActiveFilter, setIsActiveFilter] = useState(null);
  const [filterVisible, setFilterVisible] = useState(false);
  const [search, setSearch] = useState('');
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [currentHeatingSystem, setCurrentHeatingSystem] = useState(null);

  const [
    listHeatingSystems,
    { data: heatingSystemsData, loading: dataLoading },
  ] = useLazyQuery(LIST_HEATING_SYSTEMS);

  const [deleteHeatingSystem, { loading: deleteLoading }] = useMutation(
    DELETE_HEATING_SYSTEM,
  );

  const getHeatingSystems = async () => {
    const { skip, limit } = paginationConfig;
    try {
      await listHeatingSystems({
        variables: {
          filter: {
            ...(isOrganization && { companyId: orgId }),
            skip: (skip - 1) * limit,
            limit,
            search,
            isActive:
              isActiveFilter !== null ? JSON.parse(isActiveFilter) : null,
          },
          sort: sortConfig?.sortOn ? [{ ...sortConfig }] : [],
        },
        fetchPolicy: 'network-only',
      });
    } catch (error) {
      return error;
    }
  };

  const handleFilterApply = () => {
    getHeatingSystems();
    setFilterVisible(false);
  };

  useEffect(() => {
    if (isActiveFilter === null) {
      handleFilterApply();
    }
  }, [isActiveFilter]);

  useEffect(() => {
    getHeatingSystems();
  }, [paginationConfig, sortConfig, search]);

  const handleTableChange = (pagination, _filters, sorter) => {
    setPaginationConfig({
      skip: pagination?.current,
      limit: pagination?.pageSize,
    });
    setSortConfig({
      sortOn: sorter?.order ? sorter?.field : '',
      sortBy: sorter?.order === 'descend' ? 'DESC' : 'ASC',
    });
  };

  const onSearchChange = debounce((e) => {
    const { value } = e?.target;
    setPaginationConfig({ ...paginationConfig, skip: 1 });
    setSearch(value);
  }, 600);

  const handleDeleteModalOpen = (value) => {
    setIsDeleteOpen(true);
    setCurrentHeatingSystem(value);
  };

  const handleDeleteHeatingSystem = async (id) => {
    try {
      const res = await deleteHeatingSystem({ variables: { where: { id } } });
      if (res?.data) {
        setCurrentHeatingSystem(null);
        setIsDeleteOpen(false);
        getHeatingSystems();
      }
    } catch (error) {
      return error;
    }
  };

  const baseRoute = `${
    isOrganization ? `${ROUTES?.ORGANIZATIONS}/${orgMode}/${orgId}` : ''
  }${ROUTES?.MASTER}${ROUTES?.HEATING_SYSTEMS}`;

  const renderHeaderRightContent = () => (
    <Button
      className="common-button"
      size="middle"
      icon={<PlusOutlined />}
      onClick={() => history?.push(`${baseRoute}/add`)}
    >
      Add Heating System
    </Button>
  );

  const renderHeader = () => (
    <CommonTabHeader
      heading={MODULES?.HEATING_SYSTEMS}
      isGoBackVisible={false}
    />
  );

  const heatingSystemsCard = (
    <Card
      className={
        isOrganization
          ? 'full-height-without-nav-vertical-tabs'
          : 'full-height-without-nav'
      }
    >
      <HeatingSystemsTable
        baseRoute={baseRoute}
        handleTableChange={handleTableChange}
        paginationConfig={paginationConfig}
        sortConfig={sortConfig}
        heatingSystemsData={heatingSystemsData?.adminHeatingSystems}
        loading={dataLoading}
        onSearchChange={onSearchChange}
        handleDeleteModalOpen={handleDeleteModalOpen}
        isActiveFilter={isActiveFilter}
        setIsActiveFilter={setIsActiveFilter}
        handleFilterApply={handleFilterApply}
        filterVisible={filterVisible}
        setFilterVisible={setFilterVisible}
      />
    </Card>
  );

  return (
    <>
      <CommonHeader
        heading={isOrganization ? MODULES?.MASTER : MODULES?.HEATING_SYSTEMS}
      >
        {!isOrganization && <>{renderHeaderRightContent()}</>}
      </CommonHeader>
      {isOrganization ? (
        <OrganizationMastersTab>
          {renderHeader()}
          {heatingSystemsCard}
        </OrganizationMastersTab>
      ) : (
        heatingSystemsCard
      )}
      <Modal
        title="Delete the Heating System"
        okText="Yes"
        cancelText="No"
        open={isDeleteOpen}
        onOk={() => handleDeleteHeatingSystem(currentHeatingSystem?.id)}
        okButtonProps={{ loading: deleteLoading }}
        onCancel={() => {
          setIsDeleteOpen(false);
          setCurrentHeatingSystem(null);
        }}
      >
        Are you sure you want to delete this heating system?
      </Modal>
    </>
  );
};

export default HeatingSystems;
