import { PlusOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Card, Modal } from 'antd';
import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { DEFAULT_SORT_CONFIG, MODULES, ROUTES } from '@common/constants';
import CommonHeader from '@components/CommonHeader';

import CommonTabHeader from '../../../components/CommonTabHeader';
import history from '../../../historyData';
import OrganizationMastersTab from '../../organizations/components/OrganizationMastersTab';
import RoofTypesTable from '../components/RoofTypesTable';
import { DELETE_ROOF_TYPE } from '../graphql/Mutations';
import { LIST_ROOF_TYPES } from '../graphql/Queries';

const RoofTypes = ({ isOrganization = false }) => {
  const { orgId, orgMode } = useParams();
  const [paginationConfig, setPaginationConfig] = useState({
    skip: 1,
    limit: 10,
  });
  const [sortConfig, setSortConfig] = useState(DEFAULT_SORT_CONFIG);
  const [isActiveFilter, setIsActiveFilter] = useState(null);
  const [filterVisible, setFilterVisible] = useState(false);
  const [search, setSearch] = useState('');
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [currentRoofType, setCurrentRoofType] = useState(null);

  const [
    listRoofTypes,
    { data: roofTypesData, loading: dataLoading },
  ] = useLazyQuery(LIST_ROOF_TYPES);

  const [deleteRoofType, { loading: deleteLoading }] = useMutation(
    DELETE_ROOF_TYPE,
  );

  const getRoofTypes = async () => {
    const { skip, limit } = paginationConfig;
    try {
      await listRoofTypes({
        variables: {
          filter: {
            ...(isOrganization && { companyId: orgId }),
            skip: (skip - 1) * limit,
            limit,
            search,
            isActive:
              isActiveFilter !== null ? JSON.parse(isActiveFilter) : null,
          },
          sort: sortConfig?.sortOn ? [{ ...sortConfig }] : [],
        },
        fetchPolicy: 'network-only',
      });
    } catch (error) {
      return error;
    }
  };

  const handleFilterApply = () => {
    getRoofTypes();
    setFilterVisible(false);
  };

  useEffect(() => {
    if (isActiveFilter === null) {
      handleFilterApply();
    }
  }, [isActiveFilter]);

  useEffect(() => {
    getRoofTypes();
  }, [paginationConfig, sortConfig, search]);

  const handleTableChange = (pagination, _filters, sorter) => {
    setPaginationConfig({
      skip: pagination?.current,
      limit: pagination?.pageSize,
    });
    setSortConfig({
      sortOn: sorter?.order ? sorter?.field : '',
      sortBy: sorter?.order === 'descend' ? 'DESC' : 'ASC',
    });
  };

  const onSearchChange = debounce((e) => {
    const { value } = e?.target;
    setPaginationConfig({ ...paginationConfig, skip: 1 });
    setSearch(value);
  }, 600);

  const handleDeleteModalOpen = (value, type) => {
    setIsDeleteOpen(true);
    setCurrentRoofType({ ...value, type });
  };

  const handleDeleteRoofType = async (id) => {
    try {
      const res = await deleteRoofType({ variables: { where: { id } } });
      if (res?.data) {
        setCurrentRoofType(null);
        setIsDeleteOpen(false);
        getRoofTypes();
      }
    } catch (error) {
      return error;
    }
  };

  const baseRoute = `${
    isOrganization ? `${ROUTES?.ORGANIZATIONS}/${orgMode}/${orgId}` : ''
  }${ROUTES?.MASTER}${ROUTES?.ROOF_TYPES}`;

  const renderHeaderRightContent = () => (
    <Button
      className="common-button"
      size="middle"
      icon={<PlusOutlined />}
      onClick={() => history?.push(`${baseRoute}/add`)}
    >
      Add Roof Type
    </Button>
  );

  const renderHeader = () => (
    <CommonTabHeader heading={MODULES?.ROOF_TYPES} isGoBackVisible={false} />
  );

  const roofTypesCard = (
    <Card
      className={
        isOrganization
          ? 'full-height-without-nav-vertical-tabs'
          : 'full-height-without-nav'
      }
    >
      <RoofTypesTable
        baseRoute={baseRoute}
        handleTableChange={handleTableChange}
        paginationConfig={paginationConfig}
        sortConfig={sortConfig}
        roofTypesData={roofTypesData?.adminRoofTypes}
        loading={dataLoading}
        onSearchChange={onSearchChange}
        handleDeleteModalOpen={handleDeleteModalOpen}
        isActiveFilter={isActiveFilter}
        setIsActiveFilter={setIsActiveFilter}
        handleFilterApply={handleFilterApply}
        filterVisible={filterVisible}
        setFilterVisible={setFilterVisible}
      />
    </Card>
  );

  return (
    <>
      <CommonHeader
        heading={isOrganization ? MODULES?.MASTER : MODULES?.ROOF_TYPES}
      >
        {!isOrganization && <> {renderHeaderRightContent()}</>}
      </CommonHeader>
      {isOrganization ? (
        <OrganizationMastersTab>
          {renderHeader()}
          {roofTypesCard}
        </OrganizationMastersTab>
      ) : (
        roofTypesCard
      )}
      <Modal
        title="Delete the Roof Type"
        okText="Yes"
        cancelText="No"
        open={isDeleteOpen}
        onOk={() => handleDeleteRoofType(currentRoofType?.id)}
        okButtonProps={{ loading: deleteLoading }}
        onCancel={() => {
          setIsDeleteOpen(false);
          setCurrentRoofType(null);
        }}
      >
        Are you sure you want to delete this roof type?
      </Modal>
    </>
  );
};

export default RoofTypes;
