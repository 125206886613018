import { useLazyQuery, useMutation } from '@apollo/client';
import { Form, Modal, Switch, Tooltip } from 'antd';
import { map } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ReactComponent as EditIcon } from '@assets/svg/pen-to-square-solid.svg';

import {
  BREAKPOINTS,
  CONFIG_DURATION_OPTIONS,
} from '../../../common/constants';
import CommonTable from '../../../components/CommonTable';
import SelectComponent from '../../../components/SelectComponent';
import { UPDATE_ORGANIZATION_CONFIG } from '../graphql/Mutations';
import { GET_ORGANIZATION_MEASURES_FIELD_CONFIG } from '../graphql/Queries';
import ConfigModal from './ConfigModal';

const MeasuresConfigTable = () => {
  const [form] = Form.useForm();
  const { orgId } = useParams();
  const [organizationDetails, setOrganizationDetails] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [organizationConfig, setOrganizationConfig] = useState({});
  const [isConfigModalOpen, setIsConfigModalOpen] = useState(false);
  const [isConfigBtnLoading, setIsConfigBtnLoading] = useState(false);
  const [duration, setDuration] = useState('');
  const [isDurationPrompts, setIsDurationPrompts] = useState(false);
  const [isDurationLoading, setIsDurationLoading] = useState(false);

  const [getCompanyConfig] = useLazyQuery(
    GET_ORGANIZATION_MEASURES_FIELD_CONFIG,
    {
      onCompleted: (response) => {
        setOrganizationDetails(response?.getCompanyConfig);
        setIsLoading(false);
      },
      fetchPolicy: 'network-only',
      onError() {},
    },
  );

  const [updateOrganizationConfig] = useMutation(UPDATE_ORGANIZATION_CONFIG, {
    onError: () => {},
  });

  useEffect(() => {
    setIsLoading(true);
    getCompanyConfig({
      variables: { where: { companyId: orgId } },
    });
  }, []);

  const data = organizationDetails?.recommendations?.map(
    ({ __typename, ...rest }) => rest,
  );

  const onChange = async (checked, record) => {
    const updatedConfig = { ...record, visibility: checked };
    const updatedArray = data?.map((item) => {
      if (item?.key === updatedConfig?.key) {
        return updatedConfig;
      }
      return item;
    });
    const res = await updateOrganizationConfig({
      variables: {
        where: { id: organizationDetails?.id },
        data: {
          recommendations: updatedArray,
        },
      },
    });
    if (res?.data) {
      setIsLoading(true);
      getCompanyConfig({
        variables: { where: { companyId: orgId } },
      });
    }
  };

  const handleDuration = async () => {
    setIsDurationLoading(true);
    const updatedConfig = { ...organizationConfig, timePeriod: duration };
    const updatedArray = data?.map((item) => {
      if (item?.key === updatedConfig?.key) {
        return updatedConfig;
      }
      return item;
    });
    const res = await updateOrganizationConfig({
      variables: {
        where: { id: organizationDetails?.id },
        data: {
          recommendations: updatedArray,
        },
      },
    });
    if (res?.data) {
      setIsLoading(true);
      setIsDurationPrompts(false);
      getCompanyConfig({
        variables: { where: { companyId: orgId } },
      });
    }
    setIsDurationLoading(false);
  };

  const onFinish = async (values) => {
    setIsConfigBtnLoading(true);
    const labelUpdatedArray = data?.map((item) => {
      if (item?.key === organizationConfig?.key) {
        return { ...organizationConfig, label: values?.label };
      }
      return item;
    });
    const res = await updateOrganizationConfig({
      variables: {
        where: { id: organizationDetails?.id },
        data: {
          recommendations: labelUpdatedArray,
        },
      },
    });
    setIsConfigBtnLoading(false);
    if (res?.data) {
      form?.resetFields();
      setIsConfigModalOpen(false);
      setIsLoading(true);
      getCompanyConfig({
        variables: { where: { companyId: orgId } },
      });
    }
  };

  const handleDurationChange = (value, record) => {
    setOrganizationConfig(record);
    setDuration(value);
    setIsDurationPrompts(true);
  };

  const columns = [
    {
      title: 'KEY',
      dataIndex: 'key',
      width: 250,
      ellipsis: true,
      align: 'left',
      className: 'max-width-column',
      // eslint-disable-next-line no-undef
      fixed: window?.innerWidth > BREAKPOINTS?.desktop ? 'left' : false,
    },
    {
      title: 'LABEL',
      dataIndex: 'label',
      width: 250,
      ellipsis: true,
      align: 'left',
      className: 'max-width-column',
    },
    {
      title: 'VISIBILITY',
      dataIndex: 'visibility',
      width: 130,
      ellipsis: true,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => (
        <Switch
          defaultChecked={record?.visibility}
          size="small"
          onChange={(checked) => onChange(checked, record)}
        />
      ),
    },
    {
      title: 'DURATION',
      dataIndex: 'duration',
      key: 'duration',
      width: 150,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => (
        <SelectComponent
          size="small"
          className="duration-select"
          placeholder="Select Duration"
          showAction={['click']}
          value={record?.timePeriod}
          showSearch={false}
          options={map(CONFIG_DURATION_OPTIONS, (config) => config)}
          onChange={(value) => handleDurationChange(value, record)}
          allowClear={false}
        />
      ),
    },
    {
      title: 'ACTIONS',
      dataIndex: 'actions',
      width: 150,
      ellipsis: true,
      align: 'left',
      className: 'max-width-column',
      // eslint-disable-next-line no-undef
      fixed: window?.innerWidth > BREAKPOINTS?.desktop ? 'right' : false,
      render: (_, record) => (
        <div className="action-button">
          <Tooltip title="Edit Ui Label" placement="top" zIndex={4}>
            <EditIcon
              className="mr-16 pointer svg-icon"
              onClick={() => {
                setOrganizationConfig(record);
                setIsConfigModalOpen(true);
              }}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <>
      <Modal
        title="Caution"
        okText="Yes"
        cancelText="No"
        open={isDurationPrompts}
        onOk={handleDuration}
        onCancel={() => setIsDurationPrompts(false)}
        okButtonProps={{ loading: isDurationLoading }}
      >
        Are you sure you want to change duration of {organizationConfig?.key} to
        <strong> {CONFIG_DURATION_OPTIONS?.[duration]?.label} </strong>?
      </Modal>
      <ConfigModal
        form={form}
        loadings={isConfigBtnLoading}
        isModalOpen={isConfigModalOpen}
        setIsModalOpen={setIsConfigModalOpen}
        organizationConfig={organizationConfig}
        onFinish={onFinish}
      />
      <CommonTable
        columns={columns}
        data={data || []}
        loading={isLoading}
        pagination={false}
        rowKey={(record) => record?.key}
      />
    </>
  );
};

export default MeasuresConfigTable;
