import React from 'react';

import GoBackButton from '@components/GoBackButton';
import Portal from '@components/Portal';

const CommonHeader = ({ heading = '', children, customLink = '/' }) => (
  <>
    <Portal portalId="header-title-content">
      <GoBackButton customLink={customLink} />
      <div className="portal-header">{heading}</div>
    </Portal>
    <Portal portalId="header-right-content">{children}</Portal>
    <Portal portalId="header-right-content-phones">{children}</Portal>
  </>
);

export default CommonHeader;
