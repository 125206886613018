import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const LIST_ORGANIZATIONS = gql`
  query($filter: ListCompaniesFilter, $sort: ListCompaniesSort) {
    listCompanies(filter: $filter, sort: $sort) {
      count
      data {
        createdAt
        email
        id
        isActive
        logo
        name
        slug
        url
      }
    }
  }
`;

export const GET_ORGANIZATION_DETAIL = gql`
  query($where: CompanyWhereUniqueInput!) {
    getCompany(where: $where) {
      email
      logo
      name
      slug
      url
    }
  }
`;

export const GET_ORGANIZATION_CONFIG = gql`
  query($where: GetCompanyConfigWhereInput!) {
    getCompanyConfig(where: $where) {
      id
      fonts
      formSteps {
        key
        order
      }
      themeColors
      buttons
      bookSurvey
      waitList
      layout
      updateEpcCta
      recommendationPopup
      recommendationsDefaultSort
      analytics {
        key
      }
      result {
        key
        label
        visibility
        order
      }
      recommendations {
        key
        label
        visibility
      }
      tooltip
      alignment
    }
  }
`;

export const GET_SIGNED_URL = gql`
  query($data: GetCompanyUploadSignedUrlInput!) {
    getCompanyUploadSignedUrl(data: $data) {
      key
      signedUrl
    }
  }
`;

export const LIST_UI_LABELS = gql`
  query($where: GetUILabelsWhereInput, $filter: GetUILabelsFilter) {
    getUILabels(where: $where, filter: $filter) {
      count
      data {
        value
        type
        languageCode
        key
        id
      }
    }
  }
`;

export const GET_EMAIL_CONFIG = gql`
  query($where: GetCompanyConfigWhereInput!) {
    getCompanyConfig(where: $where) {
      emailConfig {
        apiKey
        baseUrl
        email
        name
        replyToEmail
        templates {
          key
          id
        }
      }
      id
    }
  }
`;

export const GET_ORGANIZATION_RECOMMENDATION_FIELD_CONFIG = gql`
  query($where: GetCompanyConfigWhereInput!) {
    getCompanyConfig(where: $where) {
      id
      result {
        key
        label
        visibility
        timePeriod
        order
      }
    }
  }
`;

export const GET_ORGANIZATION_MEASURES_FIELD_CONFIG = gql`
  query($where: GetCompanyConfigWhereInput!) {
    getCompanyConfig(where: $where) {
      id
      recommendations {
        key
        label
        visibility
        timePeriod
      }
    }
  }
`;

export const GET_ORGANIZATION_MORTGAGE_LOAN_CONFIG = gql`
  query($where: GetCompanyConfigWhereInput!) {
    getCompanyConfig(where: $where) {
      id
      mortgageLoan {
        defaultValue
        key
        label
        max
        min
        step
        visibility
      }
    }
  }
`;

export const GET_ORGANIZATION_CUSTOMISATION_PANEL_CONFIG = gql`
  query CustomizationPanelConfig($where: GetCompanyConfigWhereInput!) {
    getCompanyConfig(where: $where) {
      id
      customizationPanelConfig {
        key
        label
        order
        visibility
      }
    }
  }
`;

export const GET_CONFIG_UPLOAD_SIGNED_URL = gql`
  query($input: GetCompanyConfigsUploadSignedUrlInput!) {
    getCompanyConfigsUploadSignedUrl(input: $input) {
      key
      signedUrl
    }
  }
`;
